var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"data-panel-container"}},[_c('full-screen-topics-toggle-tab-vertical',{attrs:{"id":"lower-toggle-tab"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingData),expression:"loadingData"}],staticClass:"spinner-div small-12 cell"},[_c('font-awesome-icon',{staticClass:"fa-4x",attrs:{"icon":"spinner","aria-hidden":"true"}}),_c('h3',[_vm._v("Loading Data")])],1),(_vm.lastSearchMethod === 'geocode' && Object.keys(this.opa.targets).length || _vm.lastSearchMethod === 'reverseGeocode' && Object.keys(this.opa.targets).length)?_c('horizontal-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loadingData),expression:"!loadingData"}],attrs:{"padding-top":"0","slots":{
      items: _vm.geocodeItems,
      buttonFinished: function() {
        _vm.$data.showTable = true
        _vm.$data.buttonHide = true
      }
    },"options":_vm.geocodeOptions}}):_vm._e(),(_vm.lastSearchMethod === 'geocode' && !_vm.loadingData && _vm.geocode.status === 'success' && !Object.keys(this.opa.targets).length)?_c('div',{staticClass:"no-opa-message"},[_vm._v(" This address could not be found using the information provided. Try using the property's OPA account number or try "),_c('a',{attrs:{"target":"_blank","href":"https://atlas.phila.gov"}},[_vm._v("atlas.phila.gov")]),_vm._v(". ")]):_vm._e(),(_vm.lastSearchMethod === 'owner search'
      || _vm.lastSearchMethod === 'block search')?_c('horizontal-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loadingData),expression:"!loadingData"}],attrs:{"slots":{
      items: function(state) {
        let data = [];
        if (state.lastSearchMethod === 'owner search') {
          data = state.ownerSearch.data;
        } else {
          data = state.blockSearch.data;
        }
        return data
      },
    },"options":_vm.ownerOptions}}):_vm._e(),(_vm.lastSearchMethod === 'shape search' && this.$store.state.shapeSearch.data !== null
      || _vm.lastSearchMethod === 'buffer search' && this.$store.state.shapeSearch.data !== null)?_c('horizontal-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loadingData),expression:"!loadingData"}],attrs:{"slots":{
      items: function(state) {
        var data = state.shapeSearch.data.rows;
        return data;
      },
    },"options":_vm.shapeOptions}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }