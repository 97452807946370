var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"background"},[_vm._m(0),_c('div',{staticClass:"grid-x align-center-middle"},[_c('div',{staticClass:"cell small-9 medium-3 text-center"},[_c('font-awesome-icon',{staticClass:"fa-3x",attrs:{"icon":"search","aria-hidden":"true"}})],1),_vm._m(1),_c('div',{staticClass:"cell small-9 medium-3 text-center"},[_c('font-awesome-icon',{staticClass:"fa-3x",attrs:{"icon":"search","aria-hidden":"true"}})],1),_vm._m(2),_c('div',{staticClass:"cell small-9 medium-3 text-center"},[_c('font-awesome-icon',{staticClass:"fa-3x",attrs:{"icon":"circle","aria-hidden":"true"}})],1),_vm._m(3),_c('div',{staticClass:"cell small-9 medium-3 text-center"},[_c('font-awesome-icon',{staticClass:"fa-3x",attrs:{"icon":"hexagon","aria-hidden":"true"}})],1),_vm._m(4),_c('div',{staticClass:"cell small-9 medium-3 text-center"},[_c('font-awesome-icon',{staticClass:"fa-3x",attrs:{"icon":"map-marker-alt","aria-hidden":"true"}})],1),_vm._m(5),_c('div',{staticClass:"cell small-9 medium-3 text-center"},[_c('img',{staticClass:"street-view-image",attrs:{"src":'images/cyclomedia_blue.png'}})]),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"text-center"},[_c('b',[_vm._v("How to use Property")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cell small-15 medium-21 text-div"},[_c('p',[_c('b',[_vm._v("Search for a single address, property account number, or Department of Records Map Registry number")])]),_c('p',[_vm._v("The map will move to the location that you have searched")]),_c('p',{staticClass:"red"},[_vm._v("* Property Search is no longer allowing Search By Owner due to privacy concerns.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cell small-15 medium-21 text-div"},[_c('p',[_c('b',[_vm._v("Search for a block")])]),_c('p',[_vm._v("Enter \"block:\" before the address to find all properties on the block.")]),_c('p',[_vm._v("The map will move to the location that you have searched")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cell small-15 medium-21 text-div"},[_c('p',[_c('b',[_vm._v("Search by buffer")])]),_c('p',[_vm._v("This will find every property within 250 feet of the property that you click")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cell small-15 medium-21 text-div"},[_c('p',[_c('b',[_vm._v("Draw a shape on the map")])]),_c('p',[_vm._v("This will return every property in the shape you draw (as long as there are fewer than 200 properties)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cell small-15 medium-21 text-div"},[_c('p',[_c('b',[_vm._v("The single property found or a buffer search will mark the property")])]),_c('p',[_vm._v("You cannot interact with this")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cell small-15 medium-21 text-div"},[_c('p',[_c('b',[_vm._v("Toggle on and off street view")])]),_c('p',[_vm._v("Half of the map panel will be taken up by the street view. If you are zoomed in far enough, circles will appear along the streets in the map. Click on a circle to change the street view location to that point.")])])
}]

export { render, staticRenderFns }